var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"privacy"},[_c('logged-heard'),(_vm.detalObj)?_c('div',{staticClass:"head"},[_c('div',{staticClass:"title-money"},[_c('span',[_vm._v(_vm._s(_vm.detalObj.position_name))]),(_vm.detalObj.salary_min != '面议')?_c('span',[_vm._v(_vm._s(_vm.detalObj.salary_min)+"-"+_vm._s(_vm.detalObj.salary_max))]):_c('span',[_vm._v(_vm._s(_vm.detalObj.salary_min))])]),_c('div',{staticClass:"center"},[_c('span',{staticStyle:{"padding":"0 1.25rem"}},[_vm._v(_vm._s(_vm.detalObj.province)+"·"+_vm._s(_vm.detalObj.experience)+"·"+_vm._s(_vm.detalObj.education))]),_c('div',{staticClass:"box-right"},_vm._l((_vm.detalObj.ent.welfare.slice(0, 12)),function(item,index){return _c('div',{key:index,staticClass:"box"},[_c('span',[_vm._v(_vm._s(item))])])}),0)]),_c('div',{staticClass:"option-1"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.detalObj.enterprise_id!==_vm.userDetail.enterprise_id),expression:"detalObj.enterprise_id!==userDetail.enterprise_id"}],staticClass:"option"},[_c('div',{staticClass:"box pointer",class:_vm.detalObj.is_collection == 1 ? 'boxcolor' : '',on:{"click":function($event){return _vm.collectClick(_vm.detalObj.id)}}},[(_vm.detalObj.is_collection == 0)?_c('img',{attrs:{"src":require("@/assets/forget/6.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/forget/9.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.detalObj.is_collection == 0 ? "收藏" : "取消收藏"))])]),_c('div',{staticClass:"box pointer",on:{"click":function($event){return _vm.sendClick(
              _vm.detalObj.hr.user_id,
              _vm.detalObj.id,
              _vm.detalObj.ent.ent_name,
              _vm.detalObj.enterprise_id,
              _vm.detalObj.ent.logo_url,
              _vm.detalObj.hr.user_name,
              _vm.detalObj.position_name,
              _vm.detalObj.hr.id
            )}}},[_c('span',[_vm._v(_vm._s(_vm.detalObj.is_connect==0 ? '立即沟通' : '继续沟通'))])]),_c('div',{staticClass:"box pointer",on:{"click":function($event){return _vm.hint(_vm.detalObj.id)}}},[_c('span',[_vm._v("申请职位")])])]),(_vm.hasLogin && _vm.userDetail.detail.completeness < 90)?_c('div',{staticClass:"right-fill pointer",on:{"click":function($event){return _vm.navTo('/my-resume')}}},[_c('span',[_vm._v("填写在线简历")])]):_vm._e()])]):_vm._e(),(_vm.detalObj)?_c('div',{staticClass:"content-area"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"notice-item"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"row"},[_c('img',{attrs:{"src":_vm.detalObj.hr.avatar_url ? _vm.detalObj.hr.avatar_url : _vm.avatar,"alt":""}}),_c('div',{staticClass:"item-text"},[_c('div',[_vm._v(_vm._s(_vm.detalObj.hr.user_name))]),_c('div',[_vm._v(_vm._s(_vm.detalObj.hr.online_status))])])]),_c('div',{staticClass:"title"},[_c('ul',[_c('li',[_vm._v("职位描述")]),_vm._l((_vm.description),function(item,index){return _c('li',{key:index,staticStyle:{"word-break":"break-all"}},[_vm._v(_vm._s(item))])}),_c('li',[_vm._v("公司介绍")]),_c('li',{staticStyle:{"word-break":"break-all"}},[_vm._v(" "+_vm._s(_vm.detalObj.ent.introduction ? _vm.detalObj.ent.introduction : "无")+" ")]),_c('li',[_vm._v("工作地址")]),_c('li',{staticStyle:{"word-break":"break-all"}},[_vm._v(_vm._s(_vm.detalObj.province)+_vm._s(_vm.detalObj.city)+_vm._s(_vm.detalObj.address))])],2)])])])]),_c('div',{staticClass:"right-login"},[_c('div',{staticClass:"login"},[_c('register',{attrs:{"showCompany":false,"entObj":_vm.detalObj.ent,"entId":_vm.detalObj.enterprise_id}})],1)])]):_vm._e(),_c('el-button',{attrs:{"type":"text"}}),_c('feet-model')],1)}
var staticRenderFns = []

export { render, staticRenderFns }